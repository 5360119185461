const isBiggerThan = (value: number) => (a: number) => a > value;

type RGB = { r: number; g: number; b: number };

export const getColorForPercentageFunction = (
  colorList: {
    value: number;
    color: RGB;
  }[],
) => {
  const colors = colorList.sort((a, b) => a.value - b.value);

  return (value: number) => {
    const foundIndex = colors
      .map((a) => a.value)
      .findIndex(isBiggerThan(value));
    const index = foundIndex !== -1 ? foundIndex : colors.length - 1;

    const lower = colors[index - 1];
    const upper = colors[index];
    const range = upper.value - lower.value;
    const upperValue = (value - lower.value) / range;
    const lowerValue = 1 - upperValue;

    const color = {
      r: Math.floor(lower.color.r * lowerValue + upper.color.r * upperValue),
      g: Math.floor(lower.color.g * lowerValue + upper.color.g * upperValue),
      b: Math.floor(lower.color.b * lowerValue + upper.color.b * upperValue),
    };

    return `rgb(${color.r}, ${color.g}, ${color.b})`;
  };
};

function hexToRgb(code: string): RGB {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    code,
  ) as string[];
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
}

function range(...hexes: string[]): { value: number; color: RGB }[] {
  return hexes.map((code, index) => ({
    value: index / hexes.length + 0.01,
    color: hexToRgb(code),
  }));
}

const LIGHT_GRAY = { r: 235, g: 237, b: 240 };
const DARK_GRAY = { r: 0, g: 0, b: 0 };

export const LIGHT_POSITIVE_HEATMAP = [
  { value: 0.0, color: LIGHT_GRAY },
  { value: 0.01, color: { r: 155, g: 233, b: 168 } },
  { value: 0.25, color: { r: 64, g: 196, b: 99 } },
  { value: 0.75, color: { r: 48, g: 161, b: 78 } },
  { value: 1.0, color: { r: 33, g: 110, b: 57 } },
];

export const DARK_POSITIVE_HEATMAP = [
  { value: 0.0, color: DARK_GRAY },
  { value: 0.01, color: { r: 155, g: 233, b: 168 } },
  { value: 0.25, color: { r: 64, g: 196, b: 99 } },
  { value: 0.75, color: { r: 48, g: 161, b: 78 } },
  { value: 1.0, color: { r: 33, g: 110, b: 57 } },
];

export const LIGHT_NEGATIVE_HEATMAP = [
  { value: 0.0, color: LIGHT_GRAY },
  ...range('F3BDA5', 'ED9879', 'EA7455', 'DE4C41', 'BB2F2C', 'BB2F2C'),
];

export const DARK_NEGATIVE_HEATMAP = [
  { value: 0.0, color: DARK_GRAY },
  ...range('F3BDA5', 'ED9879', 'EA7455', 'DE4C41', 'BB2F2C', 'BB2F2C'),
];
